import { AuthProvider, getAuth, signInWithPopup } from '@firebase/auth'
import { makeStyles } from '@mui/styles'
import React from 'react'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import { formatFirebaseError } from '@/components/molecules/text/ErrorCodes'
import { RcIcon } from '@/components/atoms/RcIcon'
import { RcTrans } from '@/components/atoms/RcTrans'
import { usePage } from '@/components/providers/site/PageProvider'
import { useFirebase } from '@/components/providers/site/FirebaseProvider'
import { useFirebaseProviders } from '@/core/hooks/useFirebaseProviders'

export const SignInWithGoogle: React.FC<{
  condensed?: boolean
  isReauthentication?: boolean
  onMfaRequired: (error: any) => void
}> = ({ condensed, isReauthentication, onMfaRequired }) => {
  const { open } = usePage()
  const { firebase, logEvent, refresh } = useFirebase()
  const { GOOGLE_PROVIDER } = useFirebaseProviders()
  const style = {
    backgroundColor: '#fff',
    color: '#000',
    '&:hover': {
      backgroundColor: '#ccc',
      color: '#333'
    }
  }

  const loginProvider = (provider: AuthProvider) => {
    const auth = getAuth(firebase)
    signInWithPopup(auth, provider).catch(error => {
      switch (error.code) {
        case 'auth/popup-closed-by-user':
        case 'auth/cancelled-popup-request':
          // noop
          break
        case 'auth/multi-factor-auth-required':
          onMfaRequired(error)
          break
        default:
          open(null, <RcTrans i18nKey={formatFirebaseError(error)} />, 5000)
      }
    })
    isReauthentication && refresh()
    logEvent('login', { method: provider?.providerId })
  }

  return (
    <>
      <RcButton
        fullWidth
        variant='contained'
        onClick={() => loginProvider(GOOGLE_PROVIDER)}
        sx={style}
      >
        <RcIcon
          icon={['fab', 'google']}
          size={condensed ? 'lg' : undefined}
          mr={condensed ? 0 : 2}
        />
        {condensed ? null : (
          <RcTrans
            i18nKey='auth:register-network'
            tOptions={{ network: 'Google' }}
          />
        )}
      </RcButton>
    </>
  )
}
