import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import { DividerProps } from '@mui/material/Divider'

export const TextDivider: React.FC<DividerProps> = ({ children, ...rest }) => {
  return (
    <Box display='flex' width='100%' alignItems='center' px={4}>
      <Box flexGrow={2}>
        <Divider {...rest} />
      </Box>
      <Box flexGrow={1} textAlign='center'>
        {children}
      </Box>
      <Box flexGrow={2}>
        <Divider {...rest} />
      </Box>
    </Box>
  )
}
