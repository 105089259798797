import {
  EmailAuthProvider,
  FacebookAuthProvider,
  GoogleAuthProvider
} from 'firebase/auth'
import { useMemo } from 'react'

/*
 * Encapsulates various Firebase providers and settings.
 */
export const useFirebaseProviders = () => {
  const providers = useMemo(() => {
    const EMAIL_PROVIDER = new EmailAuthProvider()

    const FACEBOOK_PROVIDER = new FacebookAuthProvider()

    const GOOGLE_PROVIDER = new GoogleAuthProvider()
    GOOGLE_PROVIDER.setCustomParameters({
      prompt: 'select_account'
    })

    return { EMAIL_PROVIDER, FACEBOOK_PROVIDER, GOOGLE_PROVIDER }
  }, [])

  return providers
}
