import {
  createUserWithEmailAndPassword,
  getAuth,
  sendEmailVerification,
  signInWithEmailAndPassword
} from '@firebase/auth'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Form, Formik } from 'formik'
import { TextField } from 'formik-mui'
import React, { useState } from 'react'
import * as Yup from 'yup'
import { LabeledField } from '../form/LabeledField'
import { NavigationLink } from '../navigation/NavigationLink'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import { formatFirebaseError } from '@/components/molecules/text/ErrorCodes'
import { RootRoute } from '@/core/route-keys'
import { RcTrans } from '@/components/atoms/RcTrans'
import { useOrganization } from '@/entity/organization/useOrganization'
import { useFirebase } from '@/components/providers/site/FirebaseProvider'
import { useFeatures } from '@/components/providers/site/FeatureProvider'
import { useRcTranslation } from '@/core/hooks/useRcTranslation'
import { useNavigation } from '@/core/hooks/useNavigation'
import { useLocalStorage } from '@/core/hooks/useLocalStorage'
import { ModalConfiguration } from '@/components/organisms/modal/ModalConfiguration'
import { RcTextInput } from '@/components/molecules/input/RcTextInput'
import Box from '@mui/material/Box'
import { RcIcon } from '@/components/atoms/RcIcon'

interface FormModel {
  email: string
  password: string
  confirmPassword: string
}

interface Props {
  isCreate: boolean
  isReauthentication?: boolean
  onMfaRequired: (error: any) => void
  setForgotPw: () => void
}

export const SignInWithEmail: React.FC<Props> = ({
  isCreate,
  isReauthentication,
  onMfaRequired,
  setForgotPw
}) => {
  const [error, setError] = useState('')
  const { firebase, logEvent, refresh } = useFirebase()
  const { redirect } = useOrganization()
  const { featSecureMode } = useFeatures()
  const { navTo, getPath } = useNavigation()
  const [savedEmail, setSavedEmail] = useLocalStorage({
    key: 'login-email',
    defaultValue: ''
  })
  const { t } = useRcTranslation()

  const onSubmit = async ({ email, password }: FormModel) => {
    setSavedEmail(email)

    try {
      const auth = getAuth(firebase)
      if (isCreate) {
        const create = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        )
        await sendEmailVerification(create?.user, {
          url: window.location.origin + redirect
        })
      } else {
        await signInWithEmailAndPassword(auth, email, password)
        isReauthentication && refresh()
        logEvent('login', { method: 'email' })
      }
    } catch (error: any) {
      switch (error.code) {
        case 'auth/multi-factor-auth-required':
          onMfaRequired(error)
          break
        default:
          setError((<RcTrans i18nKey={formatFirebaseError(error)} />) as any)
      }
    }

    return Promise.resolve()
  }

  const validation = Yup.object<FormModel>({
    email: Yup.string()
      .email(t('error.email-invalid'))
      .required(t('error.field-required')),
    password: Yup.string().min(8).required(t('error.password-required')),
    confirmPassword: isCreate
      ? Yup.string()
          .oneOf([Yup.ref('password'), null], t('error.passwords-must-match'))
          .required(t('error.password-confirm-required'))
      : Yup.string()
  })

  return (
    <Formik
      initialValues={{
        email: savedEmail || '',
        password: '',
        confirmPassword: ''
      }}
      validationSchema={validation}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form id='sign-in-with-email-form'>
          <Grid container direction='column' spacing={2} mb={2}>
            <Grid item>
              <LabeledField
                component={TextField}
                name='email'
                placeholder={t('shared.email-placeholder')}
                autoComplete='email'
                type='text'
                label={<RcTrans i18nKey='shared.email-address' />}
                variant='outlined'
                fullWidth
              />
            </Grid>
            <Grid item>
              <LabeledField
                component={TextField}
                placeholder={t('shared.password-placeholder')}
                name='password'
                type='password'
                autoComplete='new-password'
                label={<RcTrans i18nKey='auth:labels.password' />}
                variant='outlined'
                fullWidth
              />
            </Grid>
            {error ? (
              <Grid item>
                <Typography variant='body2' color='error'>
                  {error}
                </Typography>
              </Grid>
            ) : null}
            {isCreate ? (
              <Grid item>
                <LabeledField
                  component={TextField}
                  name='confirmPassword'
                  type='password'
                  autoComplete='new-password'
                  label={<RcTrans i18nKey='auth:labels.confirm-password' />}
                  variant='outlined'
                  fullWidth
                />
              </Grid>
            ) : (
              <Grid item>
                {isReauthentication ? null : (
                  <Typography variant='body2'>
                    <NavigationLink
                      pointer
                      className='color-primary'
                      onClick={setForgotPw}
                    >
                      <RcTrans i18nKey='auth:labels.forgot-password' />
                    </NavigationLink>
                  </Typography>
                )}
              </Grid>
            )}
          </Grid>
          <ModalConfiguration direction='row'>
            {featSecureMode || isReauthentication ? null : isCreate ? (
              <RcButton
                fullWidth
                color='secondary'
                variant='contained'
                endIcon={
                  <RcIcon icon={['fal', 'right-to-bracket']} size='sm' />
                }
                onClick={() => navTo({ root: RootRoute.Login })}
              >
                <RcTrans i18nKey='shared.login' />
              </RcButton>
            ) : (
              <RcButton
                fullWidth
                color='secondary'
                variant='contained'
                onClick={() => navTo({ root: RootRoute.Register })}
              >
                <RcTrans i18nKey='shared.register' />
              </RcButton>
            )}
            <RcButton
              fullWidth
              form='sign-in-with-email-form'
              type='submit'
              disabled={isSubmitting}
              variant='contained'
              color='primary'
              endIcon={
                isCreate ? null : (
                  <RcIcon icon={['fal', 'right-to-bracket']} size='sm' />
                )
              }
            >
              {isCreate ? (
                <RcTrans i18nKey='shared.register' />
              ) : (
                <RcTrans i18nKey='shared.login' />
              )}
            </RcButton>
          </ModalConfiguration>
        </Form>
      )}
    </Formik>
  )
}
