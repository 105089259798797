// transform errors like auth/foo -> error.foo
// so that they conform with our translation key naming convention
export const formatFirebaseError = (error?: any) => {
  return (error as { code?: string })?.code?.replace('auth/', 'error.') || ''
}

// This section is used to generate translation keys for
// error codes from returned by the Firebase auth API.
// t('error.email-already-in-use')
// t('error.invalid-email')
// t('error.operation-not-allowed')
// t('error.weak-password')
// t('error.user-disabled')
// t('error.user-not-found')
// t('error.wrong-password')
// t('error.invalid-action-code')
// t('error.expired-action-code')
// t('error.invalid-email')
// t('error.credential-already-in-use')
// t('error.provider-already-linked')
// t('error.popup-closed-by-user')
// t('error.too-many-requests')
// t('error.invalid-login-credentials')
