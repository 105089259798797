import Grid from '@mui/material/Grid'
import { Form, Formik, FormikHelpers } from 'formik'
import { TextField } from 'formik-mui'
import React, { useState } from 'react'
import * as Yup from 'yup'
import { LabeledField } from '../form/LabeledField'
import { ModalConfiguration } from '../modal/ModalConfiguration'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import { formatFirebaseError } from '@/components/molecules/text/ErrorCodes'
import { RcTrans } from '@/components/atoms/RcTrans'
import { useOrganization } from '@/entity/organization/useOrganization'
import { useFirebase } from '@/components/providers/site/FirebaseProvider'
import { useLocalStorage } from '@/core/hooks/useLocalStorage'
import { useRcTranslation } from '@/core/hooks/useRcTranslation'
import Typography from '@mui/material/Typography'

interface FormModel {
  email: string
}

interface Props {
  goBack?: () => void
  onSuccess: () => void
}

export const SendPasswordReset: React.FC<Props> = ({ goBack, onSuccess }) => {
  const [success, setSuccess] = useState(false)
  const { redirect } = useOrganization()
  const { sendPasswordReset } = useFirebase()
  const [savedEmail, setSavedEmail] = useLocalStorage<string>({
    key: 'login-email',
    defaultValue: ''
  })
  const { t } = useRcTranslation()

  const onSubmit = async (
    { email }: FormModel,
    { setFieldError }: FormikHelpers<FormModel>
  ) => {
    setSavedEmail(email)

    try {
      await sendPasswordReset(email, redirect)

      setSuccess(true)
    } catch (error) {
      setFieldError(
        'email',
        (<RcTrans i18nKey={formatFirebaseError(error)} />) as any
      )
    }

    return Promise.resolve()
  }

  const validation = Yup.object<FormModel>({
    email: Yup.string().email().required(t('error.field-required'))
  })

  const initial: FormModel = {
    email: savedEmail || ''
  }

  return success ? (
    <>
      <Typography onClick={onSuccess} variant='h3' mb={2} textAlign='center'>
        <RcTrans i18nKey='auth:password-reset-sent' />
      </Typography>
      <ModalConfiguration topAction={goBack} />
    </>
  ) : (
    <Formik
      initialValues={initial}
      validationSchema={validation}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form id='send-password-reset-form'>
          <Grid container direction='column' spacing={3}>
            <Grid item>
              <LabeledField
                component={TextField}
                fullWidth
                name='email'
                type='text'
                label='Email'
                variant='outlined'
              />
            </Grid>
            <ModalConfiguration topAction={goBack}>
              <RcButton
                type='submit'
                disabled={isSubmitting}
                form='send-password-reset-form'
                fullWidth
                variant='contained'
              >
                <RcTrans i18nKey='shared.send' />
              </RcButton>
            </ModalConfiguration>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}
