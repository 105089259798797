import { NetworkKind } from '@rallycry/api-suite-typescript/dist/models/NetworkKind'
import Color from 'color'
import { makeStyles } from '@mui/styles'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import { RcIcon } from '@/components/atoms/RcIcon'
import { RcTrans } from '@/components/atoms/RcTrans'
import { useConfig } from '@/entity/site/useConfig'
import { useFirebase } from '@/components/providers/site/FirebaseProvider'
import { useTimeoutWindow } from '@/core/hooks/useTimeoutWindow'
import { useApiError } from '@/core/hooks/useApiError'
import { useNetworkKinds } from '@/core/hooks/i18n/useNetworkKind'

export const SignInWithNetworkKind: React.FC<{
  condensed?: boolean
  isCreate: boolean
  network: NetworkKind
}> = ({ condensed, isCreate, network }) => {
  const { handle } = useApiError()
  const config = useConfig()
  const { refresh } = useFirebase()
  const { getNetworkIcon, getNetworkString } = useNetworkKinds()

  const handleCustomLogin = useTimeoutWindow(
    config.api +
      (network === NetworkKind.FROST_GIANT
        ? `/login/openid?openid_identifier=https://steamcommunity.com/openid/`
        : `/oauth2/authorization/${network?.toLocaleLowerCase()}`),
    refresh,
    handle
  )

  const determineBrandColours = (network: NetworkKind) => {
    switch (network) {
      case NetworkKind.DISCORD:
        return {
          backgroundColor: '#5865F2', // Discord Blurple
          color: '#FFF',
          '&:hover': {
            backgroundColor: Color('#5865F2').darken(0.1).hex()
          }
        }
      case NetworkKind.BOY_SCOUTS_OF_AMERICA:
        return {
          backgroundColor: '#FFF',
          color: '#000',
          '&:hover': {
            backgroundColor: '#ccc'
          }
        }
      default:
        return {}
    }
  }

  return (
    <>
      <RcButton
        fullWidth
        variant='contained'
        sx={determineBrandColours(network)}
        onClick={handleCustomLogin}
      >
        <RcIcon
          icon={getNetworkIcon(network)}
          size={condensed ? 'lg' : undefined}
          mr={condensed ? 0 : 2}
        />
        {condensed ? null : isCreate ? (
          <RcTrans
            i18nKey='auth:register-network'
            tOptions={{ network: getNetworkString(network) }}
          />
        ) : (
          <RcTrans
            i18nKey='auth:login-network'
            tOptions={{ network: getNetworkString(network) }}
          />
        )}
      </RcButton>
    </>
  )
}
