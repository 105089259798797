'use client'

import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { NetworkKind } from '@rallycry/api-suite-typescript/dist/models/NetworkKind'
import { some } from 'lodash-es'
import dynamic from 'next/dynamic'
import { useState } from 'react'
import Button from '@mui/material/Button'
import { TextField } from 'formik-mui'
import { Form, Formik, FormikHelpers } from 'formik'
import Grid from '@mui/material/Grid'
import { TextDivider } from '@/components/molecules/text/DividerText'
import { EmailContainer } from '@/components/organisms/authentication/EmailContainer'
import { SignInWithGoogle } from '@/components/organisms/authentication/SignInWithGoogle'
import { SignInWithNetworkKind } from '@/components/organisms/authentication/SignInWithNetworkKind'
import { ModalConfiguration } from '@/components/organisms/modal/ModalConfiguration'
import { RcTrans } from '@/components/atoms/RcTrans'
import { useOrganization } from '@/entity/organization/useOrganization'
import { useFeatures } from '@/components/providers/site/FeatureProvider'
import { useModal } from '@/components/organisms/modal/ModalProvider'
import { LabeledField } from '@/components/organisms/form/LabeledField'
import { useFirebase } from '@/components/providers/site/FirebaseProvider'

const AgeGate = dynamic(
  async () =>
    (await import('@/components/organisms/authentication/AgeGate')).AgeGate,
  { ssr: false, loading: () => <></> }
)

interface Props {
  isCreate: boolean
  isReauthentication?: boolean
}

type FormModel = {
  verification?: string
}

// Login types we explicitly can support
export enum LoginProvider {
  GOOGLE = 'GOOGLE',
  DISCORD = 'DISCORD',
  BOY_SCOUTS_OF_AMERICA = 'BOY_SCOUTS_OF_AMERICA',
  FROST_GIANT = 'FROST_GIANT',
  MASTERYCODING = 'MASTERYCODING'
}

export const Login = ({ isCreate, isReauthentication }: Props) => {
  const [mfaError, setMfaError] = useState<any>(null)

  const {
    devEmail,
    devGoogle,
    featAuthWithEmail,
    featAuthProviders,
    ageGate,
    featAgeGate,
    featSecureMode
  } = useFeatures()
  const { verifyTotp } = useFirebase()
  const { organization } = useOrganization()
  const { isInModalContext } = useModal()

  if (!isInModalContext) return null

  if (isCreate && !ageGate?.passed && featAgeGate) {
    return <AgeGate />
  }

  const handleSubmit = async (
    values: FormModel,
    helpers: FormikHelpers<FormModel>
  ) => {
    try {
      await verifyTotp(mfaError, values.verification)
    } catch (error: any) {
      helpers.setErrors({ verification: error.message })
    }
  }

  const enabledProviders = (featAuthProviders || '')
    .toString()
    .trim()
    .toUpperCase()
    .split(',')
    .filter((it: any) => !!it) as LoginProvider[]
  if (devGoogle) {
    enabledProviders.push(LoginProvider.GOOGLE)
  }

  const condensedNetworks = enabledProviders?.length > 2
  const emailLoginEnabled = featAuthWithEmail || devEmail

  return (
    <>
      <ModalConfiguration
        avatarPath={
          featSecureMode || isReauthentication
            ? undefined
            : organization?.image || undefined
        }
        title={
          featSecureMode ? (
            ''
          ) : isReauthentication ? (
            <RcTrans i18nKey='shared.reauthenticate' />
          ) : (
            organization?.name
          )
        }
      />

      {mfaError ? (
        <Formik
          initialValues={
            {
              verification: ''
            } as FormModel
          }
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form id='verify-code-form'>
              <LabeledField
                component={TextField}
                name='verification'
                type='text'
                variant='outlined'
                fullWidth
                label={<RcTrans i18nKey='shared.verify-code' />}
              />
              <ModalConfiguration
                subtitle={
                  <RcTrans i18nKey='registration:authenticator-hint-subtitle' />
                }
                direction='row'
              >
                <Button
                  onClick={() => setMfaError(null)}
                  fullWidth
                  variant='outlined'
                >
                  <RcTrans i18nKey='shared.cancel' />
                </Button>
                <Button
                  type='submit'
                  disabled={isSubmitting}
                  form='verify-code-form'
                  fullWidth
                >
                  <RcTrans i18nKey='shared.submit' />
                </Button>
              </ModalConfiguration>
            </Form>
          )}
        </Formik>
      ) : (
        <Stack>
          {emailLoginEnabled ? (
            <EmailContainer
              isCreate={isCreate}
              isReauthentication={isReauthentication}
              onMfaRequired={e => setMfaError(e)}
            />
          ) : null}
          {some(enabledProviders) && emailLoginEnabled ? (
            <Box my={2}>
              <TextDivider>
                <Typography color='text.secondary' variant='body2'>
                  <RcTrans i18nKey='shared.or' />
                </Typography>
              </TextDivider>
            </Box>
          ) : null}
          <Grid
            container
            direction={condensedNetworks ? 'row' : 'column'}
            spacing={6}
            justifyContent='space-evenly'
          >
            {enabledProviders?.map(it => (
              <Grid key={it} item xs={3}>
                {
                  {
                    [LoginProvider.GOOGLE]: (
                      <SignInWithGoogle
                        condensed={condensedNetworks}
                        isReauthentication={isReauthentication}
                        onMfaRequired={e => setMfaError(e)}
                      />
                    ),
                    [LoginProvider.DISCORD]: (
                      <SignInWithNetworkKind
                        condensed={condensedNetworks}
                        isCreate={isCreate}
                        network={NetworkKind.DISCORD}
                      />
                    ),
                    [LoginProvider.BOY_SCOUTS_OF_AMERICA]: (
                      <SignInWithNetworkKind
                        condensed={condensedNetworks}
                        isCreate={isCreate}
                        network={NetworkKind.BOY_SCOUTS_OF_AMERICA}
                      />
                    ),
                    [LoginProvider.FROST_GIANT]: (
                      <SignInWithNetworkKind
                        condensed={condensedNetworks}
                        isCreate={isCreate}
                        network={NetworkKind.FROST_GIANT}
                      />
                    ),
                    [LoginProvider.MASTERYCODING]: (
                      <SignInWithNetworkKind
                        condensed={condensedNetworks}
                        isCreate={isCreate}
                        network={NetworkKind.MASTERYCODING}
                      />
                    )
                  }[it]
                }
              </Grid>
            ))}
          </Grid>
        </Stack>
      )}
    </>
  )
}
