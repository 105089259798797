'use client'

import React, { useState } from 'react'
import { SendPasswordReset } from './SendPasswordReset'
import { SignInWithEmail } from './SignInWithEmail'

interface Props {
  isCreate: boolean
  isReauthentication?: boolean
  onMfaRequired: (error: any) => void
}

export const EmailContainer: React.FC<Props> = ({
  isCreate,
  isReauthentication,
  onMfaRequired
}) => {
  const [forgotPw, setForgotPw] = useState(false)

  return forgotPw ? (
    <SendPasswordReset
      goBack={() => setForgotPw(false)}
      onSuccess={() => setForgotPw(false)}
    />
  ) : (
    <SignInWithEmail
      isCreate={isCreate}
      isReauthentication={isReauthentication}
      setForgotPw={() => setForgotPw(true)}
      onMfaRequired={onMfaRequired}
    />
  )
}
